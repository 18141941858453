import './App.css';
import { Button, Card, Col, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useState } from 'react';

const { Text } = Typography

function App() {
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [result, setResult] = useState('')

  const onFinish = (values: any) => {
    form.validateFields().then(() => {
      console.log(values)
      let temp = Number(values.Temperature)
      if (temp > 91.905) {
        setResult('Stress leve: High')
      } else if (temp <= 84.005) {
        setResult('Stress leve: Low')
      } else {
        setResult('Stress leve: Normal')
      }
      setIsModalOpen(true)
    })
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Content>
      <Row justify='center' align='middle' style={{ paddingTop: 100 }}>
        <Card title='Stress level detecion web app' style={{ maxWidth: 600, width: '100%' }}>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            onFinish={onFinish}
            style={{ width: '100%' }}
          >
            <Form.Item name="Temperature" label="Temperature" rules={[{ required: true, pattern: new RegExp('^[0-9]+(?:\\.[0-9]+)?$'), message: 'Enter a number' }]}>
              <Input placeholder='90' />
            </Form.Item>
            <Form.Item name="Humidity" label="Humidity" rules={[{ required: true, pattern: new RegExp('^[0-9]+(?:\\.[0-9]+)?$'), message: 'Enter a number' }]}>
              <Input placeholder='ex. 30' />
            </Form.Item>
            <Form.Item name="Step" label="Step Cound" rules={[{ required: true, pattern: new RegExp('^[0-9]+(?:\\.[0-9]+)?$'), message: 'Enter a number' }]}>
              <Input placeholder='ex. 100' />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type='primary' htmlType='submit'>Predict</Button>
            </Form.Item>
          </Form>
        </Card>
        <Modal title="Prediction result" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }}>
          <Space>
            <Text strong>{result}</Text>
          </Space>
        </Modal>
      </Row>
    </Content >
  )
}

export default App;
